import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
// import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import LinkedInIcon from '@mui/icons-material/LinkedIn';

import logoLight from '../assets/images/static/logo-dark.png';
import logoDark from '../assets/images/static/logo-dark.png';

const scrollToSection = (sectionId) => {
  const sectionElement = document.getElementById(sectionId);
  const offset = 128;
  if (sectionElement) {
    const targetScroll = sectionElement.offsetTop - offset;
    sectionElement.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo({
      top: targetScroll,
      behavior: 'smooth'
    });
  }
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Copyright © '}
      <Link href="https://www.mplansistemas.com.br" target="_blank">
        Mplan Sistemas e Consultorias&nbsp;
      </Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

Footer.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired
};

export default function Footer(props) {
  const [urlPlatform] = React.useState('https://app.vendasplanoonline.com.br');

  const logoStyle = {
    width: props.mode === 'light' ? '150px' : '150px',
    height: 'auto'
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 4, sm: 10 },
        textAlign: { sm: 'center', md: 'left' }
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' }
          }}>
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
            <Box sx={{ mb: 1.5, textAlign: { xs: 'center', md: 'left' }, ml: { md: -1 } }}>
              <img
                src={props.mode === 'light' ? logoDark : logoLight}
                style={logoStyle}
                alt="Mplan sistemas e consultorias"
              />
            </Box>
            <Typography variant="body2" fontWeight={600} gutterBottom>
              Nossos objetivos
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={2}>
              Temos o objetivo de proporcionar ferramentas úteis para operadoras de saúde realizarem
              o gerenciamento de seus beneficiários!
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1
          }}>
          <Typography variant="body2" fontWeight={600}>
            Mapa do site
          </Typography>
          <Link
            color="text.secondary"
            onClick={() => scrollToSection('features')}
            sx={{ cursor: 'pointer' }}>
            Home
          </Link>
          <Link
            color="text.secondary"
            onClick={() => scrollToSection('faq')}
            sx={{ cursor: 'pointer' }}>
            FAQ
          </Link>
          <Link
            color="text.secondary"
            onClick={() => scrollToSection('hero')}
            sx={{ cursor: 'pointer' }}>
            Contate-nos
          </Link>
          <Link color="text.secondary" href={urlPlatform} target="_blank">
            Acessar a plataforma
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1
          }}>
          <Typography variant="body2" fontWeight={600}>
            Sobre nós
          </Typography>
          <Link
            color="text.secondary"
            href="https://mplansistemas.com.br/sobre-nos/"
            target="_blank">
            Nossa história
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 2, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider'
        }}>
        <Copyright />
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: 'text.secondary'
          }}>
          <IconButton
            color="inherit"
            href="https://br.linkedin.com/company/mplansistemas"
            target="_blank"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}>
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}

import * as React from 'react';

import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { toast, Slide } from 'react-toastify';
import configAxios from '../service/configAxios';

export default function Hero() {
  const [email, setEmail] = React.useState('');

  function handleSubmit(event) {
    event.preventDefault();
    if (!email) {
      toast.warn('Por favor, preencha o seu email', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide
      });
      return;
    }
    const lead = {
      name: 'Site de vendas',
      email: email,
      subject: 'Captação através do site de vendas',
      message: 'Entrar em contato com o email',
      created_at: Date.now()
    };

    configAxios
      .post(`/sendEmail/contact`, lead)
      .then(() => {
        toast.success('Você solicitou com sucesso o nosso contato!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide
        });
        setEmail();
      })
      .catch(() => {
        toast.warn('Não foi possível solicitar o contato, por favor tente novamente!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide
        });
      });
  }
  function handleInputChange(event) {
    setEmail(event.target.value);
  }
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat'
      })}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 }
        }}>
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h2"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)'
            }}>
            Entre em&nbsp;
            <Typography
              component="span"
              variant="h2"
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light'
              }}>
              contato
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}>
            Entre em contato consco e receba a nossa atenção para transformar a captação e
            gerenciamento de propostas de saúde em sua operadora!
          </Typography>
          <form
            style={{
              alignSelf: 'center'
            }}
            onSubmit={handleSubmit}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              alignSelf="center"
              spacing={1}
              useFlexGap
              sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}>
              <TextField
                id="outlined-basic"
                hiddenLabel
                size="small"
                variant="outlined"
                aria-label="Digite o seu melhor email"
                placeholder="Digite o seu melhor email"
                onChange={(event) => handleInputChange(event)}
                type="email"
                value={email || ''}
                required
                inputProps={{
                  autoComplete: 'off',
                  'aria-label': 'Digite o seu email'
                }}
              />
              <Button variant="outlined" color="primary" type="submit">
                Solicitar demonstração
              </Button>
            </Stack>
          </form>
          <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
            Ao clicar em &quot;Solicitar demonstração&quot; você autoriza que entremos em contato
            para realizar a demonstração e prosseguirmos com o seu atendimento.
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
